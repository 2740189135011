.c-card-category {
  width: 100%;
  max-width: 600px;
  display: block;
  background: #fff;
  background: var(--theme-content-section-background-color);
  border: 1px solid #ddd;
  border-radius: 12px;
  overflow: hidden;
}
.c-card-category__pic {
  width: 100%;
  height: 256px;
  position: relative;
}
.c-card-category__pic img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.c-card-category__body {
  padding: 16px;
}
.c-card-category__body__ttl {
  width: 100%;
  height: 29px;
  font-size: 18px;
  font-weight: bold;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.c-card-category__body__description {
  width: 100%;
  height: 64px;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-top: 4px;
}
.c-card__pic__bottom {
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  bottom: 0;
}
.c-card__pic__bottom p {
  max-width: calc(50% - 2px);
  font-size: 12px;
  line-height: 22px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.8);
  padding: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.c-card__pic__bottom p + p {
  margin-left: 4px;
}
@media screen and (max-width: 768px) {
  .c-card-category__pic {
    height: 200px;
  }
}
